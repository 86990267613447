import React, { useState } from 'react';

export default function ContactForm() {
    const [paymentType, setPaymentType] = useState('free');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        city: '',
        gender: 'Male',
        category: ''
    });

    const handlePaymentTypeChange = (type) => {
        setPaymentType(type);
    };

    // Handle form input change
    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    // Handle form submission and forward data to WhatsApp
    const handleSubmit = (e) => {
        e.preventDefault();
        const whatsappMessage = `Name: ${formData.name}\nEmail: ${formData.email}\nMobile: ${formData.mobile}\nCity: ${formData.city}\nGender: ${formData.gender}\nCategory: ${formData.category}\nPayment Type: ${paymentType}`;
        const whatsappUrl = `https://api.whatsapp.com/send?phone=8279848804&text=${encodeURIComponent(whatsappMessage)}`;
        window.open(whatsappUrl, '_blank');
    };

    return (
        <div>
            <section id="contact" className="contact main-heading team p-5">
                <div className="container main-contact-from ">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="contact-info">
                                <div className="kode-section-title">
                                    <h3>Contact Info</h3>
                                </div>
                                <div className="kode-forminfo">
                                    <p>If you have any queries, feel free to reach out to us by filling in the contact form. We are here to assist with any inquiries related to sports academies, events, services, and more. Our team will get back to you promptly based on the details provided.</p>
                                    <ul className="kode-form-list">
                                        <li>
                                            <i className="fa fa-home"></i>
                                            <strong>Address:</strong>
                                            <p>Khiladi India office near Krishna Sagar Hotel gangneher Muradnagar near, near Krishna Sagar Hotel, Ghaziabad, Uttar Pradesh 201206</p>
                                        </li>
                                        <li>
                                            <i className="fa fa-phone"></i>
                                            <strong>Phone:</strong>
                                            <p>+91-827 984 8804</p>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                            <div className="contact-us">
                                <form method="post" className="comments-form" id="contactform" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                className="form-control"
                                                placeholder="Enter Your Name *"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                className="form-control"
                                                placeholder="Enter Your Email *"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <select
                                                id="category"
                                                className="form-control"
                                                value={formData.category}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">Select Category</option>
                                                <option value="web">Web</option>
                                                <option value="app">App</option>
                                                <option value="design">Design</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <input
                                                type="text"
                                                name="mobile"
                                                id="mobile"
                                                className="form-control"
                                                placeholder="Enter Your Mobile *"
                                                value={formData.mobile}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        {/* Add city field in the same row with 50% width */}
                                        <div className="col-md-6">
                                            <input
                                                type="text"
                                                id="city"
                                                className="form-control"
                                                placeholder="Your City Name"
                                                value={formData.city}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <select
                                                id="gender"
                                                value={formData.gender}
                                                onChange={handleInputChange}
                                                className="form-control"
                                            >
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                    </div>

                                    {/* Payment Method Section */}
                                    <div className="row payment-section mt-3">
                                        <div className="col-md-12">
                                            <label className="labelsbke">Choose Payment Type:</label>
                                            <div className="payment-options">
                                                <button
                                                    type="button"
                                                    className={`payment-btn ${paymentType === 'free' ? 'active' : ''}`}
                                                    onClick={() => handlePaymentTypeChange('free')}
                                                >
                                                    Free
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`payment-btn ${paymentType === 'paid' ? 'active' : ''}`}
                                                    onClick={() => handlePaymentTypeChange('paid')}
                                                >
                                                    Paid
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Send Button */}
                                    <div className="row mt-3">
                                        <div className="col-md-12 text-right">
                                            <input className="btn-color" type="submit" value="Send" />
                                        </div>
                                    </div>

                                    <div className="hidden-me" id="contact_form_responce">
                                        <p></p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

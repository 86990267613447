import React, { useEffect } from 'react';

import khiladiindia from '../assets/khiladilogo.png';
import VendorCard from '../components/VendorCard';
import SCHOOL_DATA from '../dummyData/school.json'
import VendorCard2 from '../components/VendorCard2';
import { Outlet } from 'react-router-dom';

export default function VenderListingSchool() {


    return (
        <div>
            {SCHOOL_DATA.map((card, index) => (
                <VendorCard2 key={index} data={card} />
            ))}


        </div>
    );
}




import React from 'react';
import "./StayHealthy.css";
import "./owl.carousel.min.css";
import "./owl.theme.default.min.css";
import "./aos.css";
import p1 from "../assets/img_1.jpg";
import p2 from "../assets/img_2.jpg";
import p3 from "../assets/img_3.jpg";
import p4 from "../assets/img_4.jpg";
import p5 from "../assets/img_5.jpg";

export default function StayHealthy() {
  // Exercise data
  const exercises = [
    { id: 1, image: p1, title: "Biceps Curl", instructor: "Justin Daniel", duration: "30 minutes" },
    { id: 2, image: p2, title: "Triceps Pushdown", instructor: "Justin Daniel", duration: "30 minutes" },
    { id: 3, image: p3, title: "Leg Press", instructor: "Justin Daniel", duration: "30 minutes" },
    { id: 4, image: p4, title: "Chest Press", instructor: "Justin Daniel", duration: "30 minutes" },
    { id: 5, image: p5, title: "Deadlift", instructor: "Justin Daniel", duration: "30 minutes" },
    { id: 6, image: p1, title: "Pull-Up", instructor: "Justin Daniel", duration: "30 minutes" },
    { id: 7, image: p2, title: "Lat Pulldown", instructor: "Justin Daniel", duration: "30 minutes" },
    { id: 8, image: p3, title: "Squat", instructor: "Justin Daniel", duration: "30 minutes" },
    { id: 9, image: p4, title: "Cable Fly", instructor: "Justin Daniel", duration: "30 minutes" },
    { id: 10, image: p5, title: "Crunches", instructor: "Justin Daniel", duration: "30 minutes" }
  ];

  return (
    <div className="site-section" id="classes-section">
      <div className="container">
        <div className="row justify-content-center text-center mb-5">
          <div className="col-md-8 section-heading">
            <h2 className="heading mb-3">Fitness Trainer & Sports Coach</h2>
            <p>Our gym offers a variety of classes targeting different muscle groups, designed by expert trainers. Whether you're looking to build strength, tone your body, or improve your endurance, we have the perfect workout routine for you.</p>
          </div>
        </div>

        <div className="row">
          {exercises.map((exercise) => (
            <div className="col-lg-6" key={exercise.id}>
              <div className="class-item d-flex align-items-center" data-aos="zoom-in-down">
                <a href="single.html" className="class-item-thumbnail">
                  <img src={exercise.image} alt={exercise.title} />
                </a>
                <div className="class-item-text">
                  <h2><a href="single.html">{exercise.title}</a></h2>
                  <span>By {exercise.instructor}</span>,
                  <span>{exercise.duration}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import "./StayHealthy.css";
import "./owl.carousel.min.css";
import "./owl.theme.default.min.css";
import "./aos.css";
import p1 from "../assets/img_1.jpg";
import p2 from "../assets/img_2.jpg";
import p3 from "../assets/img_3.jpg";
import p4 from "../assets/img_4.jpg";
import p5 from "../assets/img_5.jpg";
import { Link } from 'react-router-dom';

export default function DeffenceTrainingCenter() {
  // Data for exercises
  const exercises = [
    { id: 1, image: p1, title: "Biceps Curl", instructor: "Justin Daniel", duration: "30 minutes" },
    { id: 2, image: p2, title: "Triceps Pushdown", instructor: "Justin Daniel", duration: "30 minutes" },
    { id: 3, image: p3, title: "Leg Press", instructor: "Justin Daniel", duration: "30 minutes" },
    { id: 4, image: p4, title: "Chest Press", instructor: "Justin Daniel", duration: "30 minutes" },
    { id: 5, image: p5, title: "Deadlift", instructor: "Justin Daniel", duration: "30 minutes" },
    { id: 6, image: p1, title: "Pull-Up", instructor: "Justin Daniel", duration: "30 minutes" },
    { id: 7, image: p2, title: "Lat Pulldown", instructor: "Justin Daniel", duration: "30 minutes" },
    { id: 8, image: p3, title: "Squat", instructor: "Justin Daniel", duration: "30 minutes" },
    { id: 9, image: p4, title: "Cable Fly", instructor: "Justin Daniel", duration: "30 minutes" },
    { id: 10, image: p5, title: "Crunches", instructor: "Justin Daniel", duration: "30 minutes" }
  ];

  return (
    <div className="site-section" id="classes-section">
      <div className="container">
        <div className="row justify-content-center text-center mb-5">
          <div className="col-md-8 section-heading">
            <h2 className="heading mb-3">Defence Training Center</h2>
          </div>
        </div>
        
        <div className="row">
          {/* Map over exercises to dynamically generate content */}
          {exercises.map((exercise) => (
            <div className="col-lg-6" key={exercise.id}>
              {/* Wrap the entire card in the Link */}
              <Link to="/AcademyVender1" className="class-item d-flex align-items-center" data-aos="zoom-in-down">
                <div className="class-item-thumbnail">
                  <img src={exercise.image} alt={exercise.title} />
                </div>
                <div className="class-item-text">
                  <h2>{exercise.title}</h2>
                  <span>By {exercise.instructor}</span>, <span>{exercise.duration}</span>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import khiladilogo from '../assets/khiladilogo.png';
import { Link } from 'react-router-dom';
import comingSoonGif from '../assets/coming-soon.gif'; // Ensure the path to your GIF is correct
import Autocomplete from 'react-autocomplete'; // Optional: Only if using react-autocomplete

export default function Navbar() {
  // Existing states
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [showJobModal, setShowJobModal] = useState(false);
  const [showInvestModal, setShowInvestModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedOption, setSelectedOption] = useState('Free');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    city: '',
    gender: 'Male',
    category: 'HR'
  });

  // New states for autocomplete
  const [searchInput, setSearchInput] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  // Dynamic category options
  const searchCategoryNav = [
    { value: '', label: 'Category' },
    { value: 'sports_trainer', label: 'Sports Academies' },
    { value: 'Sports School', label: 'Sports School' },
    { value: 'Sports College', label: 'Sports College' },
    { value: 'Sports Education', label: 'Sports Education' },
    { value: 'Equipments', label: 'Equipments' },
    { value: 'Sports NGO', label: 'Sports NGO' },
    { value: 'Federations', label: 'Federations' },
    { value: 'Sports Events', label: 'Sports Events' },
    { value: 'Sports Services', label: 'Sports Services' },
    { value: 'Infrastructure', label: 'Infrastructure' },
    { value: 'Sports Coach', label: 'Sports Coach' },
    { value: 'Fitness Trainer', label: 'Fitness Trainer' },
    { value: 'Personal Trainer', label: 'Personal Trainer' },
    { value: 'Sports Physical Coach', label: 'Sports Physical Coach' },
    { value: 'Sports nutrition', label: 'Sports Nutrition' },
    { value: 'Sports Jobs', label: 'Sports Jobs' },
    { value: 'Sports Books', label: 'Sports Books' },
    { value: 'Sports Wear & Shoes', label: 'Sports Wear & Shoes' },
    { value: 'Sports Shop', label: 'Sports Shop' },
    { value: 'Defence Training Center', label: 'Defence Training Center' },
  ].sort((a, b) => a.label.localeCompare(b.label));

  // Handle search input change
  const handleSearchInputChange = (e) => {
    const userInput = e.target.value;
    setSearchInput(userInput);

    if (userInput.length > 0) {
      const suggestions = searchCategoryNav.filter(
        (category) =>
          category.label.toLowerCase().includes(userInput.toLowerCase())
      );
      setFilteredSuggestions(suggestions);
      setShowSuggestions(true);
    } else {
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    }
  };

  // Handle selection of a suggestion
  const handleSelectSuggestion = (suggestion) => {
    setSearchInput(suggestion.label);
    setShowSuggestions(false);
    // Optionally, navigate to the category's page
    // history.push(`/category/${suggestion.value}`);
  };

  // Existing handlers...
  const handleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  const openJobModal = () => {
    setShowJobModal(true);
  };

  const openRegisterModal = () => {
    setShowRegisterModal(true);
  };

  const openInvestModal = () => {
    setShowInvestModal(true);
  };

  const openLoginModal = () => {
    setShowLoginModal(true); // Opens login modal
  };

  const closeModal = () => {
    setShowJobModal(false);
    setShowInvestModal(false);
    setShowLoginModal(false); // Closes all modals
  };

  const toggleForm = () => {
    setShowRegisterModal(!showRegisterModal);
  };

  // Toggle between Paid and Free options
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  // Handle form input change
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappMessage = `Full Name: ${formData.name}\nEmail: ${formData.email}\nNumber: ${formData.number}\nCity: ${formData.city}\nGender: ${formData.gender}\nCategory: ${formData.category}\nPayment Option: ${selectedOption}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=8279848804&text=${encodeURIComponent(whatsappMessage)}`;
    window.open(whatsappUrl, '_blank');
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  return (
    <>

      <nav className="navbar navbar-expand-lg navbar-expand-sm fixed-top navbar-light bg-light">
        
        <div className="container-fluid d-flex ms-4 justify-content-between">
          <div className="logo d-flex align-items-center position-relative">
            <div className="logo-text">
              <Link to="/" style={{ textDecoration: 'none' }}>
                <img
                  style={{
                    verticalAlign: 'baseline',
                    width: '130px',
                    height: '60px'
                  }}
                  src={khiladilogo}
                  alt="Logo"
                />
              </Link>
            </div>
            <div className="position-relative w-100 ms-3">
              <input
                type="text"
                className="form-control rounded-pill search-bar"
                placeholder="Search Category.."
                value={searchInput}
                onChange={handleSearchInputChange}
                onFocus={() => {
                  if (filteredSuggestions.length > 0) setShowSuggestions(true);
                }}
                onBlur={() => {
                  // Delay hiding suggestions to allow click events
                  setTimeout(() => setShowSuggestions(false), 100);
                }}
              />
              {showSuggestions && filteredSuggestions.length > 0 && (
                <ul className="suggestions-list">
                  {filteredSuggestions.map((suggestion) => (
                    <li
                      key={suggestion.value}
                      onMouseDown={() => handleSelectSuggestion(suggestion)}
                    >
                      {suggestion.label}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            onClick={handleNavCollapse}
            aria-controls="navbarSupportedContent"
            aria-expanded={!isNavCollapsed}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={`collapse navbar-collapse ${isNavCollapsed ? 'collapse' : 'show'}`} id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item active">
                <button className="nav-link navjobinvest" onClick={openJobModal}>
                  Job
                </button>
              </li>
              <li className="nav-item active">
                <button className="nav-link navjobinvest" onClick={openInvestModal}>
                  Invest With Us
                </button>
              </li>
              <li className="nav-item active">
                <Link className="nav-link registerNow" onClick={openRegisterModal} to="/">Register Now</Link>
              </li>
              <li className="nav-item active">
                <button className="nav-link loginNow" onClick={openLoginModal}>Login</button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Job Modal */}
      {showJobModal && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close-btn1" onClick={closeModal}>
              &times;
            </button>
            <img src={comingSoonGif} alt="Coming Soon" />
            <h5>Jobs - Coming Soon!</h5>
            <p>We are working on exciting opportunities. Stay tuned!</p>
          </div>
        </div>
      )}

      {/* Invest With Us Modal */}
      {showInvestModal && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close-btn" onClick={closeModal}>
              &times;
            </button>
            <img src={comingSoonGif} alt="Coming Soon" />
            <h5>Invest With Us - Coming Soon!</h5>
            <p>We're preparing awesome investment opportunities. Check back soon!</p>
          </div>
        </div>
      )}

      {/* Register Form */}
      {showRegisterModal && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close-btn" onClick={closeModal}>
              &times;
            </button>

            <div className="form-popup">
              <form className="form-container" onSubmit={handleSubmit}>
                <h3 className="form-title">Contact Us</h3>

                <div className="form-body">
                  <div className="left-section">
                    <label htmlFor="name">Full Name</label>
                    <input
                      type="text"
                      id="name"
                      placeholder="Full Name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                    />

                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />

                    <label htmlFor="number">Number</label>
                    <input
                      type="tel"
                      id="number"
                      placeholder="Number"
                      value={formData.number}
                      onChange={handleInputChange}
                      required
                    />

                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      id="city"
                      placeholder="Enter City"
                      value={formData.city}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="right-section">
                    <label htmlFor="gender">Gender</label>
                    <select
                      id="gender"
                      value={formData.gender}
                      onChange={handleInputChange}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>

                    <label htmlFor="category">Category</label>
                    <select
                      id="category"
                      value={formData.category}
                      onChange={handleInputChange}
                    >
                      <option value="HR">HR</option>
                      <option value="Finance">Finance</option>
                      <option value="Engineering">Engineering</option>
                      <option value="Marketing">Marketing</option>
                    </select>

                    <div className="paid-container">
                      <label>Payment Method</label>
                      <div className="payment-options">
                        <button
                          type="button"
                          className={`paid-btn ${selectedOption === 'Free' ? 'active' : ''}`}
                          onClick={() => handleOptionChange('Free')}
                        >
                          Free
                        </button>
                        <button
                          type="button"
                          className={`paid-btn ${selectedOption === 'Paid' ? 'active' : ''}`}
                          onClick={() => handleOptionChange('Paid')}
                        >
                          Paid
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-buttons">
                  <button type="submit">Submit</button>
                  <button type="reset" onClick={() => setFormData({
                    name: '',
                    email: '',
                    number: '',
                    city: '',
                    gender: 'Male',
                    category: 'HR'
                  })}>Reset</button>
                </div>

                <button type="button" className="close1-btn" onClick={toggleForm} >
                  X
                </button>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* New Login Modal */}
      {showLoginModal && (

        <div className="modal1 " onClick={closeModal}>
          <div className="login-modal mt-0" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content1">
              <div className='d-flex headerlogin'>
                <div className=''>
                  <img src={khiladilogo} alt="Logo" className="logo-left" />
                </div>
                <div className='logotext'>
                  <h2 className="login-title">Welcome</h2>
                  <p className="login-subtitle">Login for a seamless experience</p>
                </div>
              </div>
              <form>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="+91 Enter Mobile Number*"
                    className="form-control font-weight-bold"
                  />
                </div>

                <div className="form-group">
                  <input type="checkbox" id="terms" />
                  <label htmlFor="terms">I Agree to Terms and Conditions</label>
                </div>

                <button type="submit" className="btn-login">Login with OTP</button>
                <p className="or-text">Or Login Using</p>
                <button type="button" className="btn-google">
                  <img src="https://img.icons8.com/color/16/000000/google-logo.png" alt="Google" /> Google
                </button>
              </form>

              <Link to="/" className="skip-btn" onClick={closeModal}>Skip</Link>
            </div>
          </div>
        </div>

      )}

      <style>
        {`
          /* Modal styling */
          .modal {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1050;
            backdrop-filter: blur(15px);
          }

          .modal-content {
            background: white;
            padding: 20px;
            border-radius: 10px;
            width: 90%;
            max-width: 500px;
            text-align: center;
            position: relative;
            animation: fadeIn 0.3s ease-in-out;
          }

          .modal-content img {
            width: 200px;
            margin-bottom: 15px;
            display: block;
            margin-left: auto;
            margin-right: auto;
          }

          .modal-close-btn {
            position: absolute;
            top: 10px;
            right: 10px;
            background: transparent;
            border: none;
            font-size: 1rem; /* Reduced size */
            cursor: pointer;
            color: #333; /* Button color */
          }
          
          .modal-close-btn:hover {
            color: #000; /* Hover color change for better visibility */
          }

          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          /* Remove hover background color for "Jobs" and "Invest With Us" */
          .navbar-nav .navjobinvest {
            margin: 0 10px;
            font-weight: 600;
            color: #333;
            position: relative;
            padding: 10px;
            transition: color 0.3s ease, text-shadow 0.3s ease;
            background-color: transparent;
          }

          .nav-item .navjobinvest:hover {
            text-shadow: 2px 2px 8px #2991c8;
            background-color: transparent !important; /* Ensure no background color change on hover */
          }

          /* Register Now and Login Button Styles */
          .registerNow {
            background-color: #E86F2E !important;
            color: white !important;
            padding: 10px 25px !important;
            border-radius: 8px;
            width:160px;
            text-shadow: none !important;
            margin: 0 10px;
            font-weight: 600;
            color: #333;
            position: relative;
            padding: 10px;
            transition: color 0.3s ease, text-shadow 0.3s ease;
            background-color: transparent;
          }

          .registerNow::after {
            background: #E86F2E !important;
            box-shadow: 2px 2px 5px #2998c1;
          }

          .registerNow:hover{
            background-color: #E86F2E !important;
          }

          .loginNow {
            background-color: #2998c1 !important;
            color: white !important;
            padding: 10px 25px !important;
            border-radius: 8px;
            text-shadow: none !important;
            margin: 0 10px;
            font-weight: 600;
            color: #333;
            position: relative;
            padding: 10px;
            transition: color 0.3s ease, text-shadow 0.3s ease;
            background-color: transparent;
          }

          .loginNow::after {
            background: none !important;
            box-shadow: 2px 2px 5px #E86F2E;
          }

          /* New Login Modal styling */
          /* Modal styling */
          .modal1 {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1050;
            backdrop-filter: blur(15px);
          }

          .login-modal {
            background: white;
            padding: 30px;
            border-radius: 10px;
            width: 500px;
            padding-top:20px;
            text-align: center;
            position: relative;
            animation: fadeIn 0.3s ease-in-out;
          }

          .modal-content1 {
            text-align: left;
            position: relative;
          }

          .logo-left {
            position: relative;
            top: 50px;
            left: 0px;
            width: 130px; /* Adjust as per your logo size */
          }

          .modal-close-btn1 {
            position: absolute;
            top: 10px;
            right: 10px;
            background: transparent;
            border: none;
            font-size: 1.5rem;
            cursor: pointer;
            color: #333;
          }

          .modal-close-btn1:hover {
            color: #000;
          }

          .login-title {
            margin-top: 50px;
            font-size: 24px;
            font-weight: bold;
          }

          .login-subtitle {
            color: #888;
            margin-bottom: 20px;
          }

          .form-group {
            margin-bottom: 15px;
          }

          .form-group input[type="text"]{
            border:2px solid gray !important;
            font-weight:500px !important;
            padding:10px;
            font-size:16px !important;
          }

          .form-control {
            width: 100%;
            padding: 10px;
            font-size: 16px;
            border-radius: 5px;
            border: 1px solid #ddd;
          }

          .btn-login {
            background-color: #2998c1;
            color: white;
            padding: 10px 0;
            width: 100%;
            border-radius: 5px;
            border: none;
            cursor: pointer;
            margin-top: 10px;
          }

          .btn-login:hover {
            background-color: #2687af;
          }

          .or-text {
            margin: 15px 0;
            color: #888;
          }

          .btn-google {
            background-color: #fff;
            color: #555;
            padding: 10px 0;
            width: 100%;
            border-radius: 5px;
            border: 1px solid #ddd;
            cursor: pointer;
          }

          .btn-google img {
            vertical-align: middle;
            margin-right: 8px;
          }

          .skip-btn {
            display: inline-block;
            margin-top: 20px;
            color: #2998c1;
            cursor: pointer;
            text-decoration: underline;
            font-size: 14px;
          }

          /* Fade-in animation */
          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          .headerlogin{
            display:flex;
            display-direction:row;
          }
          
          .logotext{
            padding-left:35px;
          }

          /* Suggestions Dropdown */
          .suggestions-list {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            background: white;
            border: 1px solid #ddd;
            border-top: none;
            max-height: 200px;
            overflow-y: auto;
            z-index: 1000;
            list-style: none;
            margin: 0;
            padding: 0;
          }

          .suggestions-list li {
            padding: 10px;
            cursor: pointer;
          }

          .suggestions-list li:hover {
            background-color: #f0f0f0;
          }

          /* Optional: Autocomplete customization if using react-autocomplete */
          .react-autocomplete-input {
            width: 100%;
            padding: 10px;
            border-radius: 50px;
            border: 1px solid #ced4da;
          }

          .react-autocomplete-input:focus {
            outline: none;
            border-color: #80bdff;
            box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
          }

          .autocomplete-dropdown-container {
            position: absolute;
            z-index: 1000;
            background: white;
            border: 1px solid #ccc;
            border-top: none;
            max-height: 200px;
            overflow-y: auto;
            width: 100%;
          }

          .autocomplete-dropdown-container div {
            padding: 10px;
            cursor: pointer;
          }

          .autocomplete-dropdown-container div:hover {
            background-color: #f0f0f0;
          }
        `}
      </style>

    </>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import '../Styles.css';
import sportAcademy from '../assets/sport-academy.png';
import sportEducation from '../assets/sport-education.png';
import ngo from '../assets/sport-ngo.png';
import federation from '../assets/sport-fedaration.png';
import service from '../assets/sport-service.png';
import construction from '../assets/sport-construction.png';
import trainer from '../assets/sport-trainer-coach.png';
import pTrainer from '../assets/sport-personal.png';
import phyEduCoach from '../assets/sport-phy-edu-coach.png';
import injuryDoc from '../assets/sport-doctor.png';
import jobs from '../assets/sport-job.png';
import Equipment from "../assets/Equipment.png";
import event from "../assets/Event.png";
import school from "../assets/school.png";
import graduation from "../assets/graduation.png";
import sportTrainer from "../assets/sportTrainer.png";
import nutrition from "../assets/nutrition.png";

function CategoryMenu() {
    const categories = [
        { src: sportAcademy, alt: 'Sport Academies', label: 'Sport Academies', link: '/sport-academies' },
        { src: school, alt: 'Sports School', label: 'Sports School', link: '/sports-school' },
        { src: graduation, alt: 'Sports College', label: 'Sports College', link: '/sports-college' },
        { src: sportEducation, alt: 'Sports Education', label: 'Sports Education', link: '/sports-education' },
        { src: Equipment, alt: 'Equipments', label: 'Equipments', link: '/equipments' },
        { src: ngo, alt: 'Sports NGO', label: 'Sports NGO', link: '/sports-ngo' },
        { src: federation, alt: 'Federations', label: 'Federations', link: '/federations' },
        { src: event, alt: 'Sports Events', label: 'Sports Events', link: '/sports-events' },
        { src: service, alt: 'Sports Services', label: 'Sports Services', link: '/sports-services' },
        { src: construction, alt: 'Infrastructure', label: 'Infrastructure', link: '/infrastructure' },
        { src: trainer, alt: 'Sports Coach', label: 'Sports Coach', link: '/sports-coach' },
        { src: sportTrainer, alt: 'Fitness Trainer', label: 'Fitness Trainer', link: '/fitness-trainer' },
        { src: pTrainer, alt: 'Personal Trainer', label: 'Personal Trainer', link: '/personal-trainer' },
        { src: phyEduCoach, alt: 'Physical Coach', label: 'Physical Coach', link: '/physical-coach' },
        { src: nutrition, alt: 'Sports Nutrition', label: 'Sports Nutrition', link: '/sports-nutrition' },
        { src: injuryDoc, alt: 'Injury Doctor', label: 'Injury Doctor', link: '/injury-doctor' },
        { src: jobs, alt: 'Sports Jobs', label: 'Sports Jobs', link: '/sports-jobs' },
        { src: jobs, alt: 'Sports Books', label: 'Sports Books', link: '/sports-books' },
        { src: jobs, alt: 'Sports Wear & Shoes', label: 'Sports Wear & Shoes', link: '/sports-wear-shoes' },
        { src: jobs, alt: 'Sports Shops', label: 'Sports Shops', link: '/sports-shops' },
        { src: jobs, alt: 'Defence Training Center', label: 'Defence Training Center', link: '/defence-training-center' },
    ];

    return (
        <>
            <style>
                {`
                    button:hover {
                        background-color: #E86F2E !important;
                        transform: scale(1.05) !important;
                    }

                    /* After 1359px, reduce image and card size */
                    @media (max-width: 1359px) {
                        .category-menu-item {
                            width: 120px !important;
                            height: 120px !important;
                        }
                        .category-menu-item img {
                            width: 80px !important;
                        }
                    }

                    /* For smaller screens (responsive design) */
                    @media (max-width: 1200px) {
                        .category-menu-item {
                            flex: 0 0 22% !important;
                        }
                    }

                    @media (max-width: 992px) {
                        .category-menu-item {
                            flex: 0 0 30% !important;
                        }
                    }

                    @media (max-width: 768px) {
                        .category-menu-item {
                            flex: 0 0 45% !important;
                        }
                    }

                    @media (max-width: 576px) {
                        .category-menu-item {
                            flex: 0 0 90% !important;
                        }
                    }
                `}
            </style>
            <div className='mt-5'>
                <div className="container-fluid">
                    <div className="categoryMenuList d-flex flex-column align-items-center">
                        {/* First Row */}
                        <div className="row justify-content-center mb-4">
                            {categories.slice(0, 8).map((category, index) => (
                                <div key={index} className="category-menu-item col-md-2 d-flex align-items-center flex-column text-center justify-content-center" style={categoryItemStyle}>
                                    <Link to={category.link} style={linkStyle}>
                                        <img src={category.src} alt={category.alt} style={imageStyle} />
                                        <p style={paragraphStyle}>{category.label}</p>
                                    </Link>
                                </div>
                            ))}
                        </div>
                        {/* Second Row */}
                        <div className="row justify-content-center">
                            {categories.slice(8, 16).map((category, index) => (
                                <div key={index + 8} className="category-menu-item col-md-2 d-flex align-items-center flex-column text-center justify-content-center" style={categoryItemStyle}>
                                    <Link to={category.link} style={linkStyle}>
                                        <img src={category.src} alt={category.alt} style={imageStyle} />
                                        <p style={paragraphStyle}>{category.label}</p>
                                    </Link>
                                </div>
                            ))}
                        </div>
                        {/* Button Section */}
                        <div className="row d-flex justify-content-end mt-4 mb-3" style={{ width: '100%' }}>
                            <div className="col-md-2 me-5 d-flex justify-content-end">
                                <button style={moreServicesButtonStyle}>More Services</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const categoryItemStyle = {
    width: "150px",
    height: "150px",
    borderRadius: "20px",
    backgroundColor: "#2991c8",
    padding: "10px",
    margin: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.2s",
    cursor: "pointer",  // Makes the cursor a pointer
};

const imageStyle = {
    backgroundColor: "#2991c8",
    borderRadius: "50%",
    width: "100px",
    padding: "2px",
    overflow: "hidden",
};

const paragraphStyle = {
    fontSize: "14px",
    fontWeight: "bold",
    color: "white",
};

const linkStyle = {
    textDecoration: "none",  // Removes the default link underline
    color: "inherit",  // Inherits the font color
};

const moreServicesButtonStyle = {
    padding: "10px 20px",
    borderRadius: "5px",
    backgroundColor: "#2991c8",
    color: "white",
    border: "none",
    cursor: "pointer",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    transition: "background-color 0.3s, transform 0.3s",
};

export default CategoryMenu;

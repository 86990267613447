import React from 'react'
// import Navbar from '../components/Navbar'
import HomeBanner from '../components/HomeBanner'
import CategoryMenu from '../components/CategoryMenu'
import WeatherApp from '../components/WeatherApp'
import ServiceCard from '../components/ServiceCard';
import Trainers from "../components/Trainers"
import RatedTrainers from '../components/RatedTrainers'
import ContactForm from '../components/ContactForm'

import Service2 from '../components/Service2'
import StayHealthy from '../components/StayHealthy'
import WhyJoinUs from '../components/WhyJoinUs'
import DeffenceTrainingCenter from '../components/DeffenceTrainingCenter'


function HomePage() {
  return (
    <div>
      <HomeBanner />  
      <CategoryMenu />
      <DeffenceTrainingCenter/>
      <Trainers />
      <StayHealthy/>
      <WhyJoinUs/>
      <Service2 />
      <ServiceCard />
      <RatedTrainers />
      {/* <PopUpMessage/> */}
      <WeatherApp />
      <ContactForm />
    </div>
  )
}

export default HomePage

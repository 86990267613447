import React, { useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom'; // Import Link from react-router-dom
import khiladiindia from '../assets/khiladilogo.png';
import VendorCard from '../components/VendorCard';
import ACADMIC_DATA from '../dummyData/acadmic.json'

export default function VenderListingAcademy() {
  

    return (
        <div>
            {ACADMIC_DATA.map((card, index) => (
                <VendorCard key={index} data={card} />
            ))}

           
        </div>
    );
}



import React from 'react';
import './RatedTrainers.css';
import myphoto1 from '../assets/myphoto1.jpg';
import img1 from '../assets/myphoto2.jpg';
import img2 from '../assets/myphoto2.jpg';
// import person1 from '../assets/myphoto5.jpg';

export default function RatedTrainers() {
  // Array to hold post data
  const posts = [
    {
      imgSrc: img1,
      imgAlt: 'Romolu transfer news',
      title: 'Swimming Pool',
       
    },
    {
      imgSrc: myphoto1,
      imgAlt: 'Kai\'s double goal victory',
      title: 'Construction & Maintenance',
       
    },
    {
      imgSrc: img2,
      imgAlt: 'Dogba transfer news',
      title: 'Sports Academy Equipments & Operating Providers',
       
    },
  ];

  return (
    <div className="latest-news mt-5">
      <div className="container mt-5">
        <div className="row mt-5">
          <div className="col-12 title-section text-center">
            <h2>Top Rated Services</h2>
          </div>
        </div>
        <div className="row no-gutters">
          {/* Dynamically render posts */}
          {posts.map((post, index) => (
            <div key={index} className="col-md-4">
              <div className="post-entry">
                <a href="/">
                  <img src={post.imgSrc} alt={post.imgAlt} className="img-fluid" />
                </a>
                <div className="caption">
                  <div className="caption-inner">
                    <h3 className="mb-3">{post.title}</h3>
                    <div className="author d-flex align-items-center">
                      {/* <div className="img mb-2 mr-3">
                        <img src={post.authorImg} alt={`Author ${post.authorName}`} />
                      </div> */}
                      {/* <div className="text">
                        <h4>{post.authorName}</h4>
                        <span>{post.date} &bullet; {post.category}</span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

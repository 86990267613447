import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "./SliderBanner.css";
import myphoto1 from '../assets/myphoto1.jpg';
import myphoto2 from '../assets/myphoto2.jpg';
import myphoto3 from '../assets/myphoto3.png';
import myphoto4 from '../assets/myphoto4.jpg';
import myphoto5 from '../assets/myphoto5.jpg';
import myphoto6 from '../assets/myphoto6.jpg';
import myphoto7 from '../assets/myphoto7.jpg';

// Sample data for the image slider
const imageData = [
  {
    id: 1,
    imageUrl: [myphoto1],
    title: "Card Title 1",
    description: "Description for card 1",
  },
  {
    id: 2,
    imageUrl: [myphoto2],
    title: "Card Title 2",
    description: "Description for card 2",
  },
  {
    id: 3,
    imageUrl: [myphoto3],
    title: "Card Title 3",
    description: "Description for card 3",
  },
  {
    id: 4,
    imageUrl: [myphoto4],
    title: "Card Title 4",
    description: "Description for card 4",
  },
  {
    id: 5,
    imageUrl: [myphoto5],
    title: "Card Title 5",
    description: "Description for card 5",
  },
  {
    id: 6,
    imageUrl: [myphoto6],
    title: "Card Title 6",
    description: "Description for card 6",
  },
  {
    id: 7,
    imageUrl: [myphoto7],
    title: "Card Title 7",
    description: "Description for card 7",
  },
];

const ImageCardSlider = () => {
  // Slider settings for responsiveness and autoplay
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024, // For tablet screens
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For mobile screens
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <style>
        {`
          .slider-container {
            margin-top: 20px;
            padding: 20px;
          }

          .slider-title {
            font-size: 1.5rem;
            font-weight: 600;
            display: flex;
            justify-content: center; /* Center the title */
            align-items: center !important;
          }

          .slider-title h2 {
            align-items: center !important;
            font-size: 2rem;
            font-weight: 500;
            color:#000;
          }

          .more-trainers-link {
            font-size: 16px;
            font-weight: 300;
            text-decoration: none;
            background-color: #2991c8;
            padding: 10px 20px;
            float: right;
            border-radius: 5px;
            margin-top: 20px;
            color: white; 
            transition: background-color 0.3s ease, color 0.3s ease;
          }

          .more-trainers-link:hover {
            background-color: #E86F2E;
            color: white;
            text-decoration: none;
          }

          .card {
            padding-bottom: 20px;
          }

          .btntrainer {
            background-color: #2991c8;
            padding: 8px;
            border-radius: 5px;
            color: white;
            margin-bottom: 20px !important;
          }

          .btntrainer:hover {
            background-color: #E86F2E;
            color: white;
          }

          .myslider {
            padding: 10px;
          }

          .card-image {
            object-fit: cover;
            width: 100%;
            height: 250px;
            border-radius: 8px;
            transition: transform 0.3s ease, box-shadow 0.3s ease;
          }

          .card-image:hover {
            transform: scale(1.05);
            box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
          }

          .card-content {
            text-align: center;
            padding-top: 10px;
          }

          .card-title {
            font-size: 1.2rem;
            font-weight: 600;
            margin-top: 0px;
          }

          .card-description {
            color: #6c757d;
          }
    
        `}
      </style>

      <div className="container">
        <div className="slider-container">
          {/* Title and More Trainers Link */}
          <div className="slider-title px-3 mb-3">
            <h2>Sports Shop</h2>
          </div>

          <Slider className="myslider" {...settings}>
            {imageData.map((item) => (
              <div className="card" key={item.id}>
                <img src={item.imageUrl} alt={item.title} className="card-image" />
                <div className="card-content">
                  <h3 className="card-title">{item.title}</h3>
                  <p className="card-description">{item.description}</p>
                  <a href="#" className="btntrainer">
                    Know More
                  </a>
                </div>
              </div>
            ))}
          </Slider>
          <Link to="/" className="more-trainers-link">More Trainers</Link>
        </div>
      </div>
    </>
  );
};

export default ImageCardSlider;

import React, { useEffect } from 'react';
import Equipment from "../assets/Equipment.png";
import event from "../assets/Event.png";
import school from "../assets/school.png";
import graduation from "../assets/graduation.png";
import physicalCoach from "../assets/sport-phy-edu-coach.png";
import neutrition from "../assets/nutrition.png";
import ngo from "../assets/sport-ngo.png";
import Ptrainer from "../assets/sport-personal.png";
import SportDoc from "../assets/sport-doctor.png";
import 'aos/dist/aos.css';
import './Service2.css';
import AOS from 'aos';

export default function Service2() {
  useEffect(() => {
    AOS.init();
  }, []);

  const serviceCard=[
    {
      src:event,
      imgAlt: "Sport Event",
      sportName: "Sports Event",
      details:"We organize seamless sports events, ensuring top-notch logistics and experiences.",
    },
    {
      src:Equipment,
      imgAlt: "Equipment Rentals",
      sportName: "Equipment Rentals",
      details: "Rent quality sports gear for any event or training needs.",
    },
    {
      src:school,
      imgAlt: "Sport School",
      sportName: "Sports School",
      details:"Offering training programs for athletes of all levels.",
    },
    {
      src:graduation,
      imgAlt: "Sport College",
      sportName: "College Programs",
      details:"Blending academic and athletic excellence for future careers.",
    },
    {
      src:physicalCoach,
      imgAlt: "Sports Trainers",
      sportName: "Sports Trainers",
      details:" Personalized training from certified coaches.",
    },
    {
      src:neutrition,
      imgAlt: "Sport Neutritions",
      sportName: "Neutritions",
      details:"Expert meal plans for peak athletic performance.",
    },
    {
      src:ngo,
      imgAlt: "Sport NGO",
      sportName: "Sports NGO",
      details:"Promoting sports in underprivileged communities.",
    },
    {
      src:Ptrainer,
      imgAlt: "Personal Trainers",
      sportName: "Personal Trainer",
      details:"Tailored workouts to match your fitness goals.",
    },
    {
      src:SportDoc,
      imgAlt: "Fitness Trainers",
      sportName: "Fitness Trainers",
      details:"Improve your fitness through customized workouts with our best trainers.",
    },
  ];

  return (
    <div>
      <section className="services py-lg-4">
        <div className="container">
          <div className="title">
            <h2 data-aos="fade-up" data-aos-duration="1000">Our Services</h2>
            <p data-aos="fade-up" data-aos-duration="1500">
              Solutions for Athletes and Sports Enthusiasts
            </p>
          </div>
          <div className="row pt-4">
            {/* Sports Events */}
            {serviceCard.map((serviceCard2,index)=>(
            <div key={index} className="col-lg-4 col-md-6 mb-3" data-aos="fade-up" data-aos-duration="1000">
              <a href="/" className="">
                <div className="d-flex service-card">
                  <div className="flex-shrink-0">
                    <div className="services-img">
                      <img src={serviceCard2.src} alt={serviceCard2.imgAlt} className="img-fluid" />
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h1>{serviceCard2.sportName}</h1>
                    <p>{serviceCard2.details}</p>
                  </div>
                </div>
              </a>
            </div>
            ))}
          </div>

          <div className="center-button">
            <a href="services.html" className="btn main-button" id="Second-button">More Services</a>
          </div>
        </div>
      </section>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Button } from 'react-bootstrap';
import { FaPhone, FaMapMarkerAlt, FaClock, FaEnvelope, FaShare } from 'react-icons/fa';
import { CiStar } from 'react-icons/ci';
import { FaStar } from "react-icons/fa";
import khiladilogo from '../assets/khiladilogo.png';
import { useParams } from 'react-router-dom';
import ACADMIC_DATA from '../dummyData/acadmic.json'




export default function VenderPage1(){

  const { academiesId } = useParams();

  const getSingleItem = ACADMIC_DATA.find(item => item.id === Number(academiesId) )

// Sample photos data
const PHOTOTAB = [
  { imgurl: khiladilogo, name: 'Photo 1' },
  { imgurl: khiladilogo, name: 'Photo 2' },
  { imgurl: khiladilogo, name: 'Photo 3' },
  { imgurl: khiladilogo, name: 'Photo 4' },
  { imgurl: khiladilogo, name: 'Photo 5' },
  { imgurl: khiladilogo, name: 'Photo 6' },
  { imgurl: khiladilogo, name: 'Photo 7' },
  { imgurl: khiladilogo, name: 'Photo 8' },
  { imgurl: khiladilogo, name: 'Photo 9' },
  { imgurl: khiladilogo, name: 'Photo 10' },
  { imgurl: khiladilogo, name: 'Photo 11' },
  { imgurl: khiladilogo, name: 'Photo 12' },
];

return (
  <div>
  
      <VendorCard1  data1={getSingleItem} photoTab={PHOTOTAB} />
  
  </div>
);
}
function VendorCard1({ data1, photoTab }) {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const [rating, setRating] = useState(0);  // State to manage rating

  // Sample vendor data as an array of objects
  
  // Function to handle button clicks
  const handlePhoneClick = () => {
    window.location.href = "tel:" + data1.phone;  // Initiates a phone call
  };

  const handleWhatsAppClick = () => {
    window.open("https://wa.me/918279848804", "_blank");  // Redirect to WhatsApp with the number
  };

  const handleRatingClick = (index) => {
    setRating(index + 1);  // Set the rating on star click
  };

  const handleCopyUrl = () => {
    const shareUrl = window.location.href;

    navigator.clipboard.writeText(shareUrl)
      .then(() => {
        console.log("URL copied to clipboard:", shareUrl);
        alert("URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy URL:", err);
      });
  };

  const handleGetDirections = () => {
    // Redirect to the Google Maps link
    window.location.href = "https://maps.app.goo.gl/LJ8ePFVC4Pbk71mF6";
  };

  // Styles
  if (!data1) return null; 

  return (
   <div style={styles.container}>
      <div className="VenderBanner">
        {/* Add Vender Banner Image or Content Here */}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-2">
            <div className="mt-2" style={styles.heading}>
              <h2>{data1.name} (Vender heading)</h2>
            </div>
          </div>
          <div className="col-md-12" style={styles.ratingContainer}>
            <div className="d-flex gap-3 align-items-center">
              <div className="">
                <button className="btn text-light" style={styles.buttonGreen}>{data1.rating} ⭐</button>
              </div>
              <div className="d-flex mb-0">
                <strong className='mb-0'>
                  <i style={styles.medalicon} className="fa-solid fa-medal mt-1"></i>
                  {data1.award}
                </strong>
              </div>
              <p className='text-gray mb-0'><span style={styles.openattext}>Opens at </span> {data1.openingHours}</p>
              <div className="">
                <p className='text-gray fw-500 mb-0' style={styles.yearinsport}>{data1.yearsInBusiness} years in Sports</p>
              </div>
            </div>
            <div style={styles.ratingContainer}>
              <p style={styles.ratetext} className="mb-1 fw-bold">Click to rate:</p>
              <div style={styles.stars}>
                {[...Array(5)].map((star, index) => (
                  <div
                    key={index}
                    style={{
                      ...styles.star,
                      color: index < rating ? 'orange' : '#ccc' // Update star color based on rating
                    }}
                    onClick={() => handleRatingClick(index)}
                  >
                    {index < rating ? <FaStar /> : <CiStar />}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="col-md-12">
              <div className="d-flex gap-3 mt-3" style={styles.buttonsWrapper}>
                <button style={styles.buttonGreen} onClick={handlePhoneClick}>
                  <i className="fa-solid fa-phone me-2"></i>{data1.phone}
                </button>
                <button style={styles.buttonWhite} onClick={handleWhatsAppClick}>
                  <i style={styles.whatsappIcon} className="fa-brands fa-square-whatsapp me-2 fs-4"></i>WhatsApp
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-4">
        <div className="row">
          {/* Left Side: Main Content */}
          <div className="col-md-9 col-12 order-1 order-md-1">
            <Tabs defaultActiveKey="About" id="uncontrolled-tab-example" className="mb-3">
              {/* About Tab */}
              <Tab eventKey="About" title="About">
                <h3>Company Info</h3>
                <p>{data1.description}</p>
              </Tab>

              {/* Catalogue Tab */}
              <Tab eventKey="Catelogue" title="Catelogue">
                <div className="row row-gap-2">
                  {photoTab.map((item, index) => (
                    <div key={index} className="col-6 col-md-3 text-center">
                      <div className="productContainer border rounded p-2 mb-3">
                        <img src={item.imgurl} className="rounded w-100" height="100" alt={item.name} />
                        <h6 className="py-2">{item.name}</h6>
                        <a href="#" className="btn btn-outline-primary btn-sm">Ask For Price</a>
                      </div>
                    </div>
                  ))}
                </div>
              </Tab>

              {/* Contact Tab */}
              <Tab eventKey="Contact" title="Contact">

              </Tab>
            </Tabs>
          </div>

          {/* Right Side: Contact Info */}

          <div className="col-md-3 col-12 order-2 order-md-2 mt-4 mt-md-0" style={styles.rightColumn}>
            <h3 style={styles.heading}>Contact</h3>
            <div style={styles.contactDetails}>
              <p><FaPhone style={styles.iconStyle} />{data1.phone}</p>
              <p><FaEnvelope style={styles.iconStyle} />{data1.email}</p>
              <p><FaMapMarkerAlt style={styles.iconStyle} />{data1.address}</p>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13988.06295289815!2d77.5238279!3d28.7787901!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cf5b574c74883%3A0x47234f4e49e96286!2sKhiladi%20India%20Sports%20Services!5e0!3m2!1sen!2sin!4v1727206716560!5m2!1sen!2sin" width="250" height="200" style={{ border: "0", marginTop: "20px" }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              <Button variant="primary" className="mb-3" onClick={handleGetDirections}>
                Get Directions
              </Button>
              <p><FaClock style={styles.iconStyle} />{data1.openingHours}</p>
              <Button onClick={handleCopyUrl} className="btn btn-outline-primary text-light">
                <FaShare />&nbsp;Share
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div> 
  );
  
}
const styles = {
  container: {
    padding: '20px',
  },
  heading: {
    textAlign: 'left',
  },
  buttonGreen: {
    color: 'white',
    backgroundColor: 'green',
    borderRadius: '8px',
    padding: '5px 10px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  buttonWhite: {
    color: 'black',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '5px 10px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  whatsappIcon: {
    backgroundColor: 'white',
    borderRadius: '8px',
    fontSize: '20px',
    color: 'green',
  },
  openattext: {
    fontWeight: '700',
    color: 'green',
  },
  ratingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  stars: {
    display: 'flex',
    gap: '5px',
    cursor: 'pointer',
    fontSize: '30px',
  },
  star: {
    padding: '7px',
    borderRadius: '8px',
    fontSize: '35px',
  },
  ratetext: {
    textAlign: 'center',
  },
  buttonsWrapper: {
    display: 'flex',
    gap: '10px',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  rightColumn: {
    paddingLeft: '20px',
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'left',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  headingContact: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '15px',
  },
  contactDetails: {
    lineHeight: '1.8',
  },
  iconStyle: {
    marginRight: '8px',
    color: '#1a73e8',
  },
  yearinsport: {
    fontWeight: 'bold',
    color: '#2998c1',
  },
  medalicon: {
    color: 'red',
  },
  // AskMePrice:{
  //   alignItems:'center',
  //   display:'flex',
  //   width:'70%',
  // }
};


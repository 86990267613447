import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import khiladiindia from '../assets/khiladilogo.png'

function VendorCard({ data }) {
    const [isHovered, setIsHovered] = React.useState(false);
    const [isCallHovered, setIsCallHovered] = React.useState(false);  // State for call button hover
    const [isWhatsAppHovered, setIsWhatsAppHovered] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
    }, []);

    const handleNavigation = (id) => {
        if (id > 2) {
            setShowModal(true); // Show modal if id is greater than 2
        } else {
            navigate(`/sport-academies/${id}`); // Navigate to the vendor page if id is 2 or less
        }
    };

    const handleCloseModal = () => {
        setShowModal(false); // Close modal
    };

    return (
        <>
            {/* Modal for vendor with id > 2 */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Important Notice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Comeback after few days...... Thanks 🩷
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={handleCloseModal} className="btn btn-primary">
                        Close
                    </button>
                </Modal.Footer>
            </Modal>

            {/* Vendor card */}
            <div className="container" onClick={() => handleNavigation(data.id)}>
                <div className="row">
                    <div className='col-md-9'
                        style={{
                            ...styles.cardContainer,
                            ...(isHovered ? styles.cardContainerHover : {}), // Apply hover styles
                        }}
                        onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                    >
                        <div style={styles.cardContent}>
                            <div style={styles.imageBox}>
                                <img src={khiladiindia} alt={data.name} style={styles.businessImage} />
                            </div>
                            <div style={styles.infoBox}>
                                <div style={styles.header}>
                                    <h2 style={styles.businessName}>
                                        <span style={styles.icon}>👍</span>
                                        {data.name}
                                    </h2>
                                    <div style={styles.ratings}>
                                        <span style={styles.rating}>{data.rating}</span>
                                        <span style={styles.ratingsText}>{data.ratingsCount} Ratings</span>
                                        {data.isTrending && <span style={styles.badge}>🔥 Trending</span>}
                                        {data.isTopSearch && <span style={styles.badge}>⭐ Top Search</span>}
                                    </div>
                                </div>
                                <div style={styles.location}>
                                    <span>📍 {data.location}</span>
                                </div>
                                <div style={styles.tags}>
                                    {data.tags.map((tag, index) => (
                                        <span key={index} style={styles.tag}>{tag}</span>
                                    ))}
                                </div>
                                <div style={styles.cta}>
                                    <div className="">
                                        <a href={`tel:${data.phone}`} style={{
                                            ...styles.callButton,
                                            ...(isCallHovered ? styles.callButtonHover : {}),
                                        }}
                                            onMouseEnter={() => setIsCallHovered(true)}
                                            onMouseLeave={() => setIsCallHovered(false)}
                                        >
                                            📞 {data.phone}
                                        </a>
                                    </div>

                                    <a
                                        href={`https://wa.me/${data.phone}`}
                                        style={{
                                            ...styles.whatsappButton,
                                            ...(isWhatsAppHovered ? styles.whatsappButtonHover : {}),
                                        }}
                                        onMouseEnter={() => setIsWhatsAppHovered(true)}
                                        onMouseLeave={() => setIsWhatsAppHovered(false)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        WhatsApp
                                    </a>
                                </div>
                                <div style={styles.recentEnquiries}>
                                    <span>📈 {data.recentEnquiries} people recently enquired</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">

                    </div>
                </div>
            </div>
        </>
    );
}

const styles = {
    cardContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: '1px solid #e5e5e5',
        borderRadius: '10px',
        padding: '10px',
        marginBottom: '0px',
        backgroundColor: '#fff',
        transition: 'transform 0.3s, box-shadow 0.3s',
        marginTop: '40px', // Adjust based on your navbar height
    },
    cardContainerHover: {
        transform: 'scale(1.02)',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
    },
    cardContent: {
        display: 'flex',
        width: '100%',
    },
    imageBox: {
        flexBasis: '20%',
        paddingRight: '15px',
    },
    businessImage: {
        width: '100%',
        height: 'auto',
        borderRadius: '5px',
    },
    infoBox: {
        flexBasis: '75%',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    businessName: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    icon: {
        marginRight: '10px',
    },
    ratings: {
        display: 'flex',
        alignItems: 'center',
    },
    rating: {
        color: 'green',
        fontWeight: 'bold',
        marginRight: '5px',
    },
    ratingsText: {
        marginLeft: '5px',
        color: '#555',
    },
    badge: {
        backgroundColor: 'green',
        color: 'white',
        padding: '3px 7px',
        borderRadius: '5px',
        marginLeft: '10px',
    },
    location: {
        margin: '10px 0',
        color: '#333',
    },
    tags: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '5px',
    },
    tag: {
        backgroundColor: '#f1f1f1',
        padding: '5px 10px',
        borderRadius: '5px',
    },
    cta: {
        display: 'flex',
        gap: '10px',
        marginTop: '10px',
    },
    callButton: {
        padding: '10px 15px',
        backgroundColor: '#2998c1',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '14px',
    },

    callButtonHover: {
        backgroundColor: '#0a8cbd',
    },
    whatsappButton: {
        padding: '10px 15px',
        backgroundColor: '#25d366',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '14px',
    },
    whatsappButtonHover: {
        backgroundColor: '#06993d',
    },
    recentEnquiries: {
        marginTop: '10px',
        fontSize: '12px',
        color: 'gray',
    }
};

export default VendorCard;

import './App.css';
import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from './pages/HomePage';
// import LoginPage from './pages/LoginPage';
import Navbar from './components/Navbar';
import TermAndConditions from './pages/TermAndConditions';
import Footer from './components/Footer';
import VenderListingAcademy from './pages/VenderListingAcademy';
import VenderPage1 from './pages/VenderPage1';
import VenderPage2 from './pages/VenderPage2';
import VenderListingSchool from './pages/VenderListingSchool';
import PopUpMessage from './components/PopUpMessage'

function App() {

  return (
    <div className=''>
      <Navbar />
      <PopUpMessage />
      <main style={{ paddingTop: "100px" }}>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/AcademyVender1' element={<VenderPage1 />} />
          <Route path='/sport-academies' element={<VenderListingAcademy />} />
          <Route path='/sport-academies/:academiesId' element={<VenderPage1 />} />
          <Route path='/sports-school' element={<VenderListingSchool />} />
          <Route path='/sports-school/:schoolId' element={<VenderPage2 />} />
          <Route path='/termAndConditions' element={<TermAndConditions />} />
          
          
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
